export function getRandNum(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export function getRandInt(min: number, max: number) {
  return Math.round(getRandNum(min, max));
}

export function parseStrictInt(value: string) {
  if (/^[-+]?(\d+|Infinity)$/.test(value)) {
    return Number(value);
  } else {
    return NaN;
  }
}

export function clamp(number: number, min: number, max: number) {
  return Math.min(Math.max(number, min), max);
}
