/**
 * Attempts to open a new browser tab with the provided link; failing that, replaces
 * the current window location with provided link.
 * @param link Link address string to open
 */
export function openLink(link: string) {
  if (window && window.location) {
    window.open(link, "_blank") || window.location.replace(link);
  }
}
