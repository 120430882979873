export function isEmpty(obj: any) {
  return [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;
}

export function chunk<T>(array: T[], chunkSize: number): T[][] {
  const result = [];
  for (let i = 0, len = array.length; i < len; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}

export function flattenArrayOrDictionary<T>(
  objects:
    | T[]
    | {
        [key: string]: T[];
      }
): T[] {
  if (Array.isArray(objects)) {
    return objects;
  }
  return Object.values(objects).flat();
}
