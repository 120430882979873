export function getHumanReadableVendorName(vendorOrVendorTable?: string): string {
  switch (vendorOrVendorTable) {
    case "icloud":
    case "IcloudContact":
      return "iCloud";
    case "google":
      return "Google";
    default:
      return "";
  }
}
