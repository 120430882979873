import { RefObject, useCallback, useEffect, useState } from "react";

export const useIsFocusIn = (ref: RefObject<HTMLElement>) => {
  const [focused, setFocused] = useState({
    isRefFocused: false,
    isBodyFocused: false,
  });

  const focusHandler = useCallback(
    (e: FocusEvent) => {
      setFocused({
        isRefFocused: Boolean(
          ref.current?.contains(e.target as Node) || ref.current?.contains(document.activeElement)
        ),
        isBodyFocused: e.target === document.body || document.activeElement === document.body,
      });
    },
    [ref]
  );

  useEffect(() => {
    document.removeEventListener("focusin", focusHandler);
    document.removeEventListener("focusout", focusHandler);
    document.addEventListener("focusin", focusHandler);
    document.addEventListener("focusout", focusHandler);
    return () => {
      document.removeEventListener("focusin", focusHandler);
      document.removeEventListener("focusout", focusHandler);
    };
  }, []);

  return focused;
};
